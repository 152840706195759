import emailjs from '@emailjs/browser';
import { useAnalytics } from 'hooks/useAnalytics';
import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ReactComponent as SvgDotPatternIcon } from '../../images/dot-pattern.svg';

const Container = tw.div`relative -mx-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold text-center mb-4`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-3 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-400`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-auto mt-8 py-4 px-8 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon,
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

const SuccessMessage = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-green-500 text-gray-100 rounded-lg relative text-center`}
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  p {
    ${tw`mt-4 text-lg`}
  }
`;

const Subheading = tw.h3`text-xl font-bold text-gray-100 text-center mb-8`;

export default function SimpleContactUs({ id }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { logAnalyticsEvent } = useAnalytics();

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    logAnalyticsEvent('contact_form_submit', {
      name: formData.get('name'),
      email: formData.get('email'),
    });

    emailjs.sendForm('service_crx5zkx', 'template_rzl1iap', e.target, 'f6Chz4kXx6BOqlYTS').then(
      (result) => {
        logAnalyticsEvent('contact_form_success', {
          response_time: result.status,
        });
        setIsSubmitted(true);
      },
      (error) => {
        logAnalyticsEvent('contact_form_error', {
          error_message: error.text,
        });
        console.log(error.text);
      },
    );

    e.target.reset();
  };

  return (
    <Container id={id}>
      <Content>
        {isSubmitted ? (
          <SuccessMessage>
            <h2>Mensagem Enviada!</h2>
            <p>Obrigado por entrar em contato conosco. Responderemos em breve.</p>
          </SuccessMessage>
        ) : (
          <FormContainer>
            <div tw="mx-auto max-w-4xl">
              <h2>Entre em Contato</h2>
              <Subheading>
                Descubra como o Licitou pode impulsionar seu sucesso em licitações
              </Subheading>
              <form onSubmit={sendEmail}>
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Seu Nome</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="name"
                        placeholder="Ex. João Silva"
                        required
                        onChange={() => {
                          logAnalyticsEvent('contact_form_field_interaction', {
                            field: 'name',
                          });
                        }}
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Seu Endereço de E-mail</Label>
                      <Input
                        id="email-input"
                        type="email"
                        name="email"
                        placeholder="Ex. joao@mail.com"
                        required
                        onChange={() => {
                          logAnalyticsEvent('contact_form_field_interaction', {
                            field: 'email',
                          });
                        }}
                      />
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="message-input">Sua Mensagem</Label>
                      <TextArea
                        id="message-input"
                        name="message"
                        placeholder="Ex. Gostaria de saber mais sobre como o Licitou pode me ajudar a ganhar mais licitações"
                        required
                        onChange={() => {
                          logAnalyticsEvent('contact_form_field_interaction', {
                            field: 'message',
                          });
                        }}
                      />
                    </InputContainer>
                  </Column>
                </TwoColumn>

                <SubmitButton type="submit">Enviar Mensagem</SubmitButton>
              </form>
            </div>
            <SvgDotPattern1 />
          </FormContainer>
        )}
      </Content>
    </Container>
  );
}
