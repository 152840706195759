import axios from 'axios';
import { auth } from '../config/firebase';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

async function getUserToken() {
  const user = auth.currentUser;
  if (!user) {
    return null;
  }
  const token = await user.getIdToken();
  if (!token || token === '') {
    return null;
  }
  return token;
}

export const loadUserData = async () => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.get('/api/auth/user-data', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const checkTrialEligibility = async () => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.get('/api/payments/trial-eligibility', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const startTrial = async (planId, durationId, companyName, fieldId) => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.post(
    '/api/payments/start-trial',
    { planId, durationId, companyName, fieldId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const getToken = async () => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.post(
    '/api/auth/get-token',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const updateUserPhone = async (phone) => {
  const token = await getUserToken();
  if (!token) {
    return null;
  }
  const response = await api.post(
    '/api/auth/update-phone',
    { phone },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};
