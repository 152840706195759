export function hasActivePlan(user) {
  if (!user) return false;

  // If user has had a plan before (trial or paid), they should go to the app
  if (user.hasHadActivePlan || user.hasHadTrial) return true;

  // For new users, check if they have selected a plan
  const hasPlan = user.planId && user.durationId;
  if (!hasPlan) return false;

  // Check if user has a valid subscription status
  const hasValidStatus = ['active', 'trialing', 'inactive'].includes(user.subscriptionStatus);

  return hasPlan && hasValidStatus && user.isActive;
}

export const companyFields = [
  {
    id: 'agriculture',
    name: 'Agricultura e Pecuária',
  },
  {
    id: 'food_industry',
    name: 'Indústria Alimentícia',
  },
  {
    id: 'health',
    name: 'Saúde e Equipamentos Médicos',
  },
  {
    id: 'technology',
    name: 'Tecnologia e Informática',
  },
  {
    id: 'construction',
    name: 'Construção Civil',
  },
  {
    id: 'transportation',
    name: 'Transportes e Logística',
  },
  {
    id: 'education',
    name: 'Educação e Treinamento',
  },
  {
    id: 'cleaning',
    name: 'Limpeza e Conservação',
  },
  {
    id: 'security',
    name: 'Segurança e Proteção',
  },
  {
    id: 'furniture',
    name: 'Móveis e Decoração',
  },
  {
    id: 'environmental',
    name: 'Meio Ambiente e Sustentabilidade',
  },
  {
    id: 'maintenance',
    name: 'Manutenção e Reparação',
  },
  {
    id: 'consulting',
    name: 'Consultoria e Gestão',
  },
  {
    id: 'textile',
    name: 'Têxtil e Vestuário',
  },
  {
    id: 'automotive',
    name: 'Automotivo',
  },
  {
    id: 'chemical',
    name: 'Indústria Química',
  },
  {
    id: 'pharmaceutical',
    name: 'Farmacêutico',
  },
  {
    id: 'sports',
    name: 'Esportes e Recreação',
  },
  {
    id: 'printing',
    name: 'Gráfica e Impressão',
  },
  {
    id: 'metal',
    name: 'Metalurgia e Siderurgia',
  },
  {
    id: 'electronics',
    name: 'Eletrônicos e Eletrodomésticos',
  },
  {
    id: 'packaging',
    name: 'Embalagens',
  },
  {
    id: 'laboratory',
    name: 'Laboratórios e Análises',
  },
  {
    id: 'events',
    name: 'Eventos e Cultura',
  },
  {
    id: 'telecom',
    name: 'Telecomunicações',
  },
  {
    id: 'beauty',
    name: 'Beleza e Estética',
  },
  {
    id: 'funeral',
    name: 'Serviços Funerários',
  },
  {
    id: 'pet',
    name: 'Serviços para Animais',
  },
  {
    id: 'religious',
    name: 'Organizações Religiosas',
  },
  {
    id: 'professional_associations',
    name: 'Associações Profissionais',
  },
  {
    id: 'repair_services',
    name: 'Serviços de Reparação',
  },
  {
    id: 'personal_services',
    name: 'Serviços Pessoais',
  },
  {
    id: 'domestic_services',
    name: 'Serviços Domésticos',
  },
  {
    id: 'medical_services',
    name: 'Serviços Médicos',
  },
  {
    id: 'dental_services',
    name: 'Serviços Odontológicos',
  },
  {
    id: 'garden_and_floriculture',
    name: 'Jardinagem e Floricultura',
  },
  {
    id: 'emergency_services',
    name: 'Serviços de Emergência',
  },
  {
    id: 'entertainment',
    name: 'Entretenimento e Mídia',
  },
  {
    id: 'real_estate',
    name: 'Imobiliário',
  },
  {
    id: 'tourism',
    name: 'Turismo e Hotelaria',
  },
  {
    id: 'financial',
    name: 'Serviços Financeiros',
  },
  {
    id: 'insurance',
    name: 'Seguros e Previdência',
  },
  {
    id: 'food_services',
    name: 'Serviços de Alimentação',
  },
  {
    id: 'mining',
    name: 'Mineração e Extração',
  },
  {
    id: 'research',
    name: 'Pesquisa e Desenvolvimento',
  },
  {
    id: 'energy',
    name: 'Energia e Utilities',
  },
];
