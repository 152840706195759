import { create } from 'zustand';
import { planDurations, plans } from '../config/plans';
import { startTrial as apiStartTrial, checkTrialEligibility } from '../services/api';

const usePlanStore = create((set, get) => ({
  selectedPlan: null,
  selectedDuration: null,
  error: null,
  success: false,
  processing: false,
  isTrialEligible: false,

  setSelectedPlan: (planId) => set({ selectedPlan: planId }),
  setSelectedDuration: (durationId) => set({ selectedDuration: durationId }),
  setError: (error) => set({ error }),
  setSuccess: (success) => set({ success }),
  setProcessing: (processing) => set({ processing }),

  getPlanDetails: () => {
    const { selectedPlan, selectedDuration } = get();
    const planDetails = plans.find((p) => p.id === selectedPlan);
    const durationDetails = planDurations.find((d) => d.id === selectedDuration);

    if (planDetails && durationDetails) {
      const durationIndex = planDurations.findIndex((d) => d.id === selectedDuration);
      const price = planDetails.price[durationIndex];
      return {
        planDetails: { ...planDetails, price },
        durationDetails,
      };
    }
    return { planDetails: null, durationDetails: null };
  },

  checkTrialEligibility: async () => {
    try {
      const data = await checkTrialEligibility();
      set({ isTrialEligible: data.isEligible });
      if (!data.isEligible && data.reason) {
        set({ error: data.reason });
      }
      return data.isEligible;
    } catch (error) {
      console.error('Error checking trial eligibility:', error);
      set({
        error:
          'Não foi possível verificar a elegibilidade do período de avaliação. Tente novamente.',
        isTrialEligible: false,
      });
      return false;
    }
  },

  startTrial: async (planId, durationId, companyName, field) => {
    set({ processing: true, error: null, success: false });
    try {
      const result = await apiStartTrial(planId, durationId, companyName, field);
      if (result.success) {
        set({
          success: true,
          error: null,
          selectedPlan: planId,
          selectedDuration: durationId,
        });
        return true;
      }
      set({
        error:
          result.message || 'Não foi possível iniciar o período de avaliação. Tente novamente.',
        success: false,
      });
      return false;
    } catch (error) {
      console.error('Error starting trial:', error);
      set({
        error: 'Ocorreu um erro ao iniciar sua avaliação. Por favor, tente novamente.',
        success: false,
      });
      return false;
    } finally {
      set({ processing: false });
    }
  },

  reset: () =>
    set({
      selectedPlan: null,
      selectedDuration: null,
      error: null,
      success: false,
      processing: false,
    }),
}));

export default usePlanStore;
